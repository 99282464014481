import $ from 'jquery';

/**
 * Fix button-click handling for cookie-consent banner
 */

export default function preventCookieConsentLayerNavigation () {

  const NAMESPACE = 'preventCookieConsentLayerNavigation';

  $('body').on(`click.${NAMESPACE}`, '.cc-banner .cc-dismiss', (event) => {
    event.preventDefault();
  });

  return Promise.resolve({
    destroy: () => {
      $('body').off(`click.${NAMESPACE}`);
    }
  });
}
