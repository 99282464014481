import $ from "jquery";

/**
 * Loading-Layer
 */
export default function buildLoadingLayerController (
  loadingLayerSelector,
  activeStateClass,
  opaqueStateClass,
  appearDelay
) {

  const $loadingLayer = $(loadingLayerSelector);
  let loadingTimeoutId;

  if (!$loadingLayer.length) {
    throw Error(`Selector [${loadingLayerSelector}] does not resolve to any element.`);
  }

  const activateInternal = () => {
    $loadingLayer.addClass(activeStateClass);
  };

  return Promise.resolve({

    activate: () => {
      loadingTimeoutId && clearTimeout(loadingTimeoutId);
      loadingTimeoutId = setTimeout(activateInternal, appearDelay);
    },

    deactivate: () => {
      loadingTimeoutId && clearTimeout(loadingTimeoutId);
      loadingTimeoutId = void 0;

      $loadingLayer.removeClass(activeStateClass);
      $loadingLayer.removeClass(opaqueStateClass);
    },

    stopWaitForLoad: () => {
      loadingTimeoutId && clearTimeout(loadingTimeoutId);
      loadingTimeoutId = void 0;
    },

    activateOpaque: () => {
      $loadingLayer.addClass(opaqueStateClass);
    },

    deactivateOpaque: () => {
      $loadingLayer.remove(opaqueStateClass);
    },

    destroy: () => {
      // noop
    }
  });
}



