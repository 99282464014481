/**
 * Entry-point for the global dependencies
 */

/*
 * Style imports
 */
import '../css/main.scss';

/*
 * Image imports
 */
import '../img/favicon-src.png';
import '../img/logo.svg';
import '../img/logo-mobile.svg';
import '../img/logo-for-animation.svg';
import '../img/logo-for-animation_inverted.svg';
import '../img/logo-mobile-for-animation.svg';
import '../img/stamp.svg';
import '../img/icon_facebook.svg';
import '../img/icon_instagram.svg';
import '../img/icon_linkedin.svg';
import '../img/icon_spotify.svg';
import '../img/icon_twitter.svg';
import '../img/icon_xing.svg';
import '../img/dropshadow_stickynav.png';
import '../img/404.gif';

/*
 * Script imports
 */
import $ from 'jquery';
import 'lazysizes';
import AOS from 'aos';
import Cookies from 'js-cookie';

import barba from '@barba/core';

import pandaHeader from './components/header';
import buildLoadingLayerController from './components/loading-layer';
import preventCookieConsentLayerNavigation from './components/cookie-consent';
import sendGoogleAnalyticsPageView from './components/analytics';
import initServiceWorker from './service-worker/sw-init';

/******************************************
 * own functions
 *****************************************/

const setActiveNavEntry = function (nextPage) {
  $('[data-nav-entry!=""]').removeClass('is-active');
  if (nextPage.match("^case-detail")) {
    nextPage = 'work';
  }
  $(`li[data-nav-entry=${nextPage}]`).addClass('is-active');
};

const showIntro = function () {
  if ($('#pandaIntro').length && $('main').data('barba-namespace') === 'about') {
    $('#pandaIntro').addClass('is-active');
    $('html').addClass('lock');
    $('body').removeClass('init');
  }
};

const initialFunctions = function () {
  /* Hide Intro animation */
  $('#pandaIntro.is-active').one('click', function () {
    Cookies.set('panda_intro-shown', 'true', {
      expires: 14
    });

    const $this = $(this);
    $this.removeClass('is-active');
    $('html').removeClass('lock');
    $this.one('transitionend', () => {
      $(window).trigger('panda:introHidden');
      $this.remove();
    });
  });

  /* Scroll to top from footer */
  $('#pandaScrollUp').click(() => {
    $('body,html').stop().animate({
      scrollTop: 0
    }, 400);
    return false;
  });

  /* Smooth scroll to anchor on page */
  $('.pandaProject__outline span.link-underline').click(function (event) {
    const $this = $(this);
    const target = $($this.data('target'));
    if( target.length ) {
      event.preventDefault();
      $('html, body').stop().animate({
        scrollTop: target.offset().top
      }, 600);
    }
  });

  preventCookieConsentLayerNavigation();
};

/******************************************
 * on first load
 *****************************************/

$(() => {
  pandaHeader();
  showIntro();
  initialFunctions();
  AOS.init({
    delay: 50,
    duration: 600,
    offset: 80,
    once: true,
    easing: 'ease-in-out'
  });
});

if (process.env.ENVIRONMENT !== 'dev') {
  initServiceWorker();
} else {
  // eslint-disable-next-line no-console
  console.log(`Skipping registration of service worker as application is running with ENVIRONMENT [${process.env.ENVIRONMENT}]`);
}


/******************************************
 * Page transitions
 *****************************************/

const BARBA_REQUEST_TIMEOUT = 10000; // amount of time to wait before barba triggers a hard page-reload
const BARBA_LOADING_TIMEOUT = 1500; // amount of time to wait before showing the loading-layer
const loadingLayerControllerPromise = buildLoadingLayerController(
  '.loading-layer',
  'loading-layer--active',
  'loading-layer--opaque',
  BARBA_LOADING_TIMEOUT
);

barba.init({
  timeout: BARBA_REQUEST_TIMEOUT,
  requestError: (trigger, action) => {
    if (action === 'click') {
      loadingLayerControllerPromise.then((controller) => controller.activateOpaque());
    }
  },
  transitions: [
    {
      name: 'default-transition',
      leave () {
        loadingLayerControllerPromise.then((controller) => controller.activate());
      },
      enter (data) {
        loadingLayerControllerPromise.then((controller) => controller.stopWaitForLoad());
        $('.pandaHeader.is-open').removeClass('is-open');
        $('.pandaHamburger.is-active').removeClass('is-active');
        $('.pandaNavFlyout.is-active').removeClass('is-active');

        const backgroundColor = (data.next.container).dataset.background;
        const mainClass = (data.next.container).dataset.mainclass;
        const bodyStyling = (data.next.container).dataset.bodybackground;
        const bodyInverted = (data.next.container).dataset.bodyinverted;
        const header = $('.pandaHeader');

        $('body').attr('style', '').attr('class', '' ).addClass(backgroundColor).removeClass('init');
        $('main').attr('class', '' ).addClass(mainClass);
        header.attr('style', '' ).removeClass('pandaHeader--inverted');
        if(bodyStyling) {
          header.attr('style', '' ).attr('style', 'background: '+ bodyStyling);
          $('body').attr('style', '' ).attr('style', 'background: '+ bodyStyling + ' !important');
          if(bodyInverted) {
            header.addClass('pandaHeader--inverted');
          }
        }

        $('html').removeClass('lock').removeClass('menu-open');
        $('body,html').delay(0).animate({
          scrollTop: 0
        }, 0);
        setActiveNavEntry(data.next.namespace);

        /* Move logo to original position */
        const defaultLogoWrapper = $('.pandaHeader > .pandaHeader__wrapper');
        const logo = $('#pandaLogo');
        logo.prependTo(defaultLogoWrapper);

      },
      afterEnter () {
        initialFunctions();
        loadingLayerControllerPromise.then((controller) => controller.deactivate());
      },
      after () {
        // we need to wait a tick as even in the after event the document title is not updated yet.
        setTimeout(sendGoogleAnalyticsPageView, 0);
      }
    }
  ],
  views: [
    (() => {
      let initializedMasonryLifecycle;
      return {
        namespace: 'work',
        beforeEnter () {
          import(/* webpackChunkName: "work", webpackPrefetch: true */ './components/toggle-customerlist')
            .then(({ default: pandaToggleCustomerList }) => {
              pandaToggleCustomerList();
            });
          import(/* webpackChunkName: "work", webpackPrefetch: true */ './components/work-masonry').then(({ default: initWorkMasonry }) => {
            initWorkMasonry(
              '.pandaProjectList',
              '.pandaProjectList__entry',
              '.pandaProjectList__masonry-sizer'
            ).then((masonryLifecycle) => {
              initializedMasonryLifecycle = masonryLifecycle;
              // refresh AOS as offsets of elements will have changed
              AOS.refresh();
            });
          });
        },
        afterLeave: () => {
          initializedMasonryLifecycle && initializedMasonryLifecycle.destroy();
          initializedMasonryLifecycle = void 0;
        }
      };
    })(), (() => {
      let initializedMasonryLifecycle;
      return {
        namespace: 'work-tags',
        beforeEnter () {
          if (!$('.pandaProjectList').length) {
            return;
          }
          import(/* webpackChunkName: "work", webpackPrefetch: true */ './components/work-masonry').then(({ default: initWorkMasonry }) => {
            initWorkMasonry(
              '.pandaProjectList',
              '.pandaProjectList__entry',
              '.pandaProjectList__masonry-sizer'
            ).then((masonryLifecycle) => {
              initializedMasonryLifecycle = masonryLifecycle;
              // refresh AOS as offsets of elements will have changed
              AOS.refresh();
            });
          });
        },
        afterLeave: () => {
          initializedMasonryLifecycle && initializedMasonryLifecycle.destroy();
          initializedMasonryLifecycle = void 0;
        }
      };
    })(), (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'case-detail',
        beforeEnter () {
          import(/* webpackChunkName: "case-detail", webpackPrefetch: true */ './components/carousel').then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [...initializedLifecycles, carouselLifecycle];
            });
          });
          import(/* webpackChunkName: "case-detail", webpackPrefetch: true */ './components/video').then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [...initializedLifecycles, videoLifecycle];
            });
          });
          import(/* webpackChunkName: "case-detail", webpackPrefetch: true */ './components/audio').then(({ default: initAudio }) => {
            initAudio().then((audioLifecycle) => {
              initializedLifecycles = [...initializedLifecycles, audioLifecycle];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach(lifecycle => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    {
      namespace: 'contact',
      beforeEnter () {
        import(/* webpackChunkName: "contact" */ './components/map').then(({ default: initMap }) => {
          initMap();
        });
      }
    }
  ]
});
