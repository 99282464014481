//////////////////////////////////////////////////////////////////
///// Menu  //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import $ from 'jquery';

export default function pandaHeader () {

  const toggleMenu = $('.pandaHeader__menu-toggle > .pandaHamburger');
  const header = $('.pandaHeader');
  const navFlyout = $('.pandaNavFlyout');
  const defaultLogoWrapper = $('.pandaHeader > .pandaHeader__wrapper');
  const logo = $('#pandaLogo');
  let flyoutOpen = false;

  const moveLogo  = function (direction, timing = 400) {
    setTimeout(function (){
      if (direction === 'default') {
        logo.prependTo(defaultLogoWrapper);
      }
      else if (direction === 'flyout') {
        logo.prependTo(navFlyout);
      }
    }, timing);
  };

  const closeFlyout = function () {
    toggleMenu.removeClass('is-active');
    navFlyout.removeClass('is-active');
    header.removeClass('is-open');
    $('html').removeClass('menu-open');
    moveLogo('default');
    flyoutOpen = false;
  };

  const openFlyout = function () {
    toggleMenu.addClass('is-active');
    navFlyout.addClass('is-active');
    header.addClass('is-open');
    $('html').addClass('menu-open');
    moveLogo('flyout', 0);

    setTimeout(function (){
      flyoutOpen = true;
    }, 800);

  };

  toggleMenu.click(function (e) {
    e.preventDefault();
    const $this = $(this);
    if($this.hasClass('is-active')) {
      closeFlyout();
    }
    else {
      openFlyout();
    }
  });

  // Close menu on esc
  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      closeFlyout();
    }
  });


  $('main').on('click', function (event) {
    if (!$(event.target).closest(navFlyout).length && !$(event.target).closest(toggleMenu).length) {
      closeFlyout();
    }
  });

  // show menu toggle when navigation scrolls out
  const menutoggleTrigger = 150;

  window._isScrolled = false;
  $(window).on('scroll', false, function () {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if ((scrollTop > menutoggleTrigger) && !window._isScrolled) {
      window._isScrolled = true;
      header.addClass('out-of-view');
    } else if (scrollTop <= menutoggleTrigger && window._isScrolled) {
      window._isScrolled = false;
      header.removeClass('out-of-view');
    } else if (flyoutOpen) {
      closeFlyout();
    }
  });

}
