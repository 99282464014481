import assert from 'assert';

/**
 * GA integration
 */

export default function sendGoogleAnalyticsPageView () {
  assert(window.gtag, 'Cannot send pageview as [gtag] is missing from window');
  assert(window.GA_MEASUREMENT_ID, 'Cannot send pageview as [GA_MEASUREMENT_ID] is missing from window');

  window.gtag('config', window.GA_MEASUREMENT_ID, {
    'page_title': document.title,
    'page_path': window.location.pathname,
    'page_location': window.location.href
  });

}
